import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
  Button,
  TextField,
  Typography
} from '@material-ui/core';

import { useMutation } from '@apollo/client';

import { ADD_CATEGORY } from 'data/mutations/mutations';

const useStyles = makeStyles(() => ({
  root: {},
  label: {
    fontWeight: 500,
    color: '#68AF27'
  }
}));

const FormFields = (props) => {
  const { className, ...rest } = props;

  const [addCategory] = useMutation(ADD_CATEGORY);

  const classes = useStyles();

  let errors = [];

  const [values, setValues] = useState({
    name: '',
    description: ''
  });

  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
  };

  function restartFields() {
    setValues({
      name: '',
      description: ''
    });
  }

  function checkCategoryForm() {
    if (values.name === '') errors.push('Nome da Categoria');
    if (values.description === '') errors.push('Descrição');

    if (errors.length === 0) return true;

    return false;
  }

  async function registerNewCategory(event) {
    try {
      event.preventDefault();
      let ref = null;
      if (checkCategoryForm()) {
        ref = addCategory({
          variables: {
            name: values.name,
            description: values.description
          }
        });
      } else {
        alert('Ops! Confira se algum campo obrigatório ficou vazio!');
      }
      if (ref !== null) {
        alert('Categoria ( ' + values.name + ' ) Cadastrado com Sucesso!');
        restartFields();
        return null;
      }
    } catch (error) {
      alert(error);
    }
  }

  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <form>
        <CardHeader
          onSubmit={(e) => e.preventDefault() && false}
          title="Cadastrar Categoria"
        />
        <Divider />
        <CardContent>
          <Typography className={classes.label}>Nome da Categoria</Typography>
          <TextField
            fullWidth
            name="name"
            onChange={handleChange}
            required
            value={values.name}
            variant="outlined"
          />
          <Typography className={classes.label} style={{ marginTop: '1rem' }}>
            Descrição
          </Typography>
          <TextField
            fullWidth
            name="description"
            onChange={handleChange}
            required
            value={values.description}
            variant="outlined"
          />
        </CardContent>
        <Divider />
        <CardActions>
          <Button
            style={{ background: '#68AF27' }}
            color="primary"
            onClick={registerNewCategory}
            variant="contained">
            Cadastrar
          </Button>
        </CardActions>
      </form>
    </Card>
  );
};

FormFields.propTypes = {
  className: PropTypes.string
};

export default FormFields;
