import React, { useState, useEffect } from 'react';
import { useQuery, useSubscription } from '@apollo/client';
import { makeStyles } from '@material-ui/styles';

import { UsersTable, UsersToolbar } from './components';
import { GETTING_PROFESSIONALS } from 'data/subscriptions/subscriptions';
import { GET_PROFESSIONAL_BY_NAME } from 'data/queries/queries';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3)
  },
  content: {
    marginTop: theme.spacing(2)
  }
}));

const UserList = () => {
  const classes = useStyles();

  const [users, setUsers] = useState([]);
  const [input, setInput] = useState('');

  const { data: rawProfessionals } = useSubscription(GETTING_PROFESSIONALS);
  const { data: filteredProfessionals } = useQuery(GET_PROFESSIONAL_BY_NAME, {
    variables: { search: `${input}%` }
  });

  useEffect(() => {
    if (filteredProfessionals !== undefined) {
      if (filteredProfessionals.hopy_User.length !== 0) {
        setUsers(filteredProfessionals.hopy_User);
      }
    } else if (rawProfessionals) {
      setUsers(
        rawProfessionals.hopy_User
      );
    }
  }, [rawProfessionals, filteredProfessionals]);

  function getSearchInput(input) {
    setInput(input);
  }

  return (
    <div className={classes.root}>
      <UsersToolbar callbackParent={getSearchInput} />
      <div className={classes.content}>
        <UsersTable users={users} />
      </div>
    </div>
  );
};

export default UserList;
