import { gql } from '@apollo/client';

export const ADD_USER = gql`
  mutation(
    $name: String!
    $address: String!
    $city: String!
    $state: String!
    $cep: String!
    $contact: String!
    $account_active: Boolean
    $sub_category_id: uuid!
    $email: String
    $cpf: String
    $cnpj: String
    $linkedin: String
    $instagram: String
    $facebook: String
    $website: String
    $description: String
    $verified_user: Boolean
    $active_professional: Boolean
  ) {
    insert_hopy_User(
      objects: {
        name: $name
        address: $address
        city: $city
        state: $state
        cep: $cep
        contact: $contact
        account_active: $account_active
        sub_category_id: $sub_category_id
        email: $email
        cpf: $cpf
        cnpj: $cnpj
        linkedin: $linkedin
        instagram: $instagram
        facebook: $facebook
        website: $website
        description: $description
        verified_user: $verified_user
        active_professional: $active_professional
      }
    ) {
      affected_rows
      returning {
        name
        address
        city
        state
        cep
        contact
        account_active
        sub_category_id
        email
        cpf
        cnpj
        linkedin
        instagram
        facebook
        website
        description
        verified_user
        active_professional
      }
    }
  }
`;

export const ADD_CATEGORY = gql`
  mutation($name: String!, $description: String!) {
    insert_hopy_Category(objects: { name: $name, description: $description }) {
      affected_rows
      returning {
        name
        description
      }
    }
  }
`;

export const ADD_SUB_CATEGORY = gql`
  mutation($name: String!, $description: String!, $category_id: uuid!) {
    insert_hopy_SubCategory(
      objects: {
        name: $name
        description: $description
        category_id: $category_id
      }
    ) {
      affected_rows
      returning {
        name
        description
        category_id
      }
    }
  }
`;

export const UPDATE_PROFESSIONAL = gql`
  mutation(
    $uid: uuid!
    $address: String!
    $city: String!
    $state: String!
    $cep: String!
    $contact: String!
    $account_active: Boolean
    $sub_category_id: uuid!
    $email: String
    $cpf: String
    $cnpj: String
    $linkedin: String
    $instagram: String
    $facebook: String
    $website: String
    $description: String
    $verified_user: Boolean
    $active_professional: Boolean
  ) {
    update_hopy_User(
      where: { uid: { _eq: $uid } }
      _set: {
        address: $address
        city: $city
        state: $state
        cep: $cep
        contact: $contact
        account_active: $account_active
        sub_category_id: $sub_category_id
        email: $email
        cpf: $cpf
        cnpj: $cnpj
        linkedin: $linkedin
        instagram: $instagram
        facebook: $facebook
        website: $website
        description: $description
        verified_user: $verified_user
        active_professional: $active_professional
      }
    ) {
      affected_rows
    }
  }
`;

export const UPDATE_ANNOUNCE_COMPLETE = gql`
  mutation(
    $uid: uuid!
    # $checked: Boolean!
    # $category_name: String
    $contact: String
    $description: String
    $title: String
    # $sub_category_name: String
    $sub_category_id: uuid
  ) {
    update_hopy_Announce(
      where: { uid: { _eq: $uid } }
      _set: {
        # checked: $checked
        contact: $contact
        description: $description
        title: $title
        sub_category_id: $sub_category_id
      }
    ) {
      affected_rows
    }
  }
`;

export const UPDATE_ANNOUNCE_CHECKED = gql`
  mutation($uid: uuid!, $checked: Boolean!) {
    update_hopy_Announce(
      where: { uid: { _eq: $uid } }
      _set: { checked: $checked }
    ) {
      affected_rows
    }
  }
`;

export const UPDATE_RATING = gql`
  mutation($id: Int!, $check: Boolean!) {
    update_hopy_Ranking(where: { id: { _eq: $id } }, _set: { check: $check }) {
      affected_rows
    }
  }
`;
