import React, { useState, useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useSubscription } from '@apollo/client';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';

import { GETTING_SUB_CATEGORIES } from 'data/subscriptions/subscriptions';

import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardActions,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  TablePagination
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: 0
  },
  content: {
    padding: 0
  },
  inner: {
    width: '100%'
  },
  infoContainer: {
    maxHeight: '71px',
    maxWidth: '280px'
  },
  actions: {
    justifyContent: 'flex-end'
  },
  link: {
    maxWidth: '200px',
    margin: 0
  },
  linkProfessional: {
    color: '#68AF27',
    fontWeight: 500
  }
}));

const UsersTable = (props) => {
  const { className, users, loading, ...rest } = props;

  const { data: subCategories } = useSubscription(GETTING_SUB_CATEGORIES);
  const [allSubCategories, setAllSubCategories] = useState([]);

  const classes = useStyles();

  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);

  useEffect(() => {
    if (subCategories) {
      setAllSubCategories(subCategories.hopy_SubCategory);
    }
  }, [subCategories]);

  const handlePageChange = (event, page) => {
    setPage(page);
  };

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(event.target.value);
  };

  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <CardContent className={classes.content}>
        <PerfectScrollbar>
          <div className={classes.inner}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Nᵒ</TableCell>
                  <TableCell>Nome</TableCell>
                  <TableCell>Função Profissional</TableCell>
                  <TableCell>Telefone</TableCell>
                  <TableCell>Email</TableCell>
                </TableRow>
              </TableHead>
              <TableBody className={classes.tableBody}>
                {users
                  .map((user, index) => (
                    <TableRow
                      key={user.uid}
                      className={classes.tableRow}
                      hover
                    >
                      <TableCell>
                        <Typography
                          className={classes.infoContainer}
                          variant="body1"
                          fontWeight="fontWeightMedium">
                          {index + 1}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <div className={classes.infoContainer}>
                          <RouterLink
                            to={{
                              pathname: '/atualizar-profissional',
                              user: user
                            }}
                            className={classes.link}>
                            <Typography
                              className={
                                user.active_professional
                                  ? classes.linkProfessional
                                  : classes.linkUser
                              }
                              variant="body1">
                              {user.name}
                            </Typography>
                          </RouterLink>
                        </div>
                      </TableCell>
                      <TableCell className={classes.infoContainer}>
                        {allSubCategories.map((sub) => {
                          if (sub.uid === user.sub_category_id) {
                            return sub.name;
                          }
                          return null;
                        })}
                      </TableCell>
                      <TableCell>
                        <Typography
                          className={classes.infoContainer}
                          variant="body1">
                          {user.contact}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography
                          className={classes.infoContainer}
                          variant="body1">
                          {user.email}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </div>
        </PerfectScrollbar>
      </CardContent>
      <CardActions className={classes.actions}>
        <TablePagination
          component="div"
          count={users.length}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={handleRowsPerPageChange}
          page={page}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={[5, 10, 20]}
        />
      </CardActions>
    </Card>
  );
};

UsersTable.propTypes = {
  className: PropTypes.string,
  users: PropTypes.array.isRequired
};

export default UsersTable;
