import React, { useState, useEffect, useCallback } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
  Grid,
  Button,
  TextField,
  Checkbox,
  Typography
} from '@material-ui/core';

import { useSubscription, useMutation } from '@apollo/client';

import { UPDATE_PROFESSIONAL } from 'data/mutations/mutations';
import { GETTING_SUB_CATEGORIES } from 'data/subscriptions/subscriptions';

const useStyles = makeStyles((theme) => ({
  root: {},
  status: {
    display: 'flex',
    alignItems: 'flex-end',
    paddingLeft: theme.spacing(2),
    marginBottom: theme.spacing(1)
  },
  label: {
    color: '#80AE38',
    fontWeight: 500
  },
  active: {
    color: '#80AE38',
    fontWeight: 500,
    paddingBottom: theme.spacing(1.2)
  },
  link: {
    color: '#fff'
  }
}));

const UpdateForm = (props) => {
  const { className, user, ...rest } = props;

  const [updateProfessional] = useMutation(UPDATE_PROFESSIONAL);
  const { data: subCategories } = useSubscription(GETTING_SUB_CATEGORIES);

  const [allSubCategories, setAllSubCategories] = useState([
    {
      name: 'Selecione uma função profissional'
    }
  ]);

  const [values, setValues] = useState({
    name: user.name || '',
    uid: user.uid || '',
    address: user.address || '',
    cep: user.cep || '',
    city: user.city || '',
    state: user.state || 'Paraíba',
    contact: user.contact || '',
    account_active: user.account_active || false,
    sub_category_id: user.sub_category_id || '',
    email: user.email || '',
    cpf: user.cpf || '',
    cnpj: user.cpf || '',
    linkedin: user.linkedin || '',
    instagram: user.instagram || '',
    facebook: user.facebook || '',
    website: user.website || '',
    description: user.description || '',
    verified_user: user.verified_user || false,
    active_professional: user.active_professional || false
  });

  useEffect(() => {
    if (subCategories) {
      setAllSubCategories((allSubCategories) => [
        ...allSubCategories,
        ...subCategories.hopy_SubCategory
      ]);
    }
  }, [subCategories]);

  const classes = useStyles();

  let errors = [];

  const handleChange = useCallback(
    (event) => {
      setValues({
        ...values,
        [event.target.name]: event.target.value
      });
    },
    [values]
  );

  const handleCheckboxChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.checked
    });
  };

  const states = [
    {
      value: 'paraiba',
      label: 'Paraíba'
    }
  ];

  function restartFields() {
    setValues({
      uid: '',
      name: '',
      address: '',
      cep: '',
      city: 'João Pessoa',
      state: 'Paraíba',
      contact: '',
      account_active: false,
      sub_category_id: '',
      email: '',
      cpf: '',
      cnpj: '',
      linkedin: '',
      instagram: '',
      facebook: '',
      website: '',
      description: '',
      verified_user: false,
      active_professional: false
    });
  }

  function checkUpdateForm() {
    if (values.address === '') errors.push('Endereço');
    if (values.city === '') errors.push('Cidade');
    if (values.state === '') errors.push('Estado');
    if (values.contact === '') errors.push('Telefone');
    if (values.sub_category_id === '') errors.push('Função Profissional');

    if (errors.length !== 0) return false;

    return true;
  }

  async function reformProfessional(event) {
    try {
      event.preventDefault();
      let ref = null;
      if (checkUpdateForm() === true) {
        ref = updateProfessional({
          variables: {
            uid: values.uid,
            address: values.address,
            cep: values.cep,
            city: values.city,
            state: values.state,
            contact: values.contact,
            account_active: values.account_active,
            sub_category_id: values.sub_category_id,
            email: values.email,
            cpf: values.cpf,
            cnpj: values.cnpj,
            linkedin: values.linkedin,
            instagram: values.instagram,
            facebook: values.facebook,
            website: values.website,
            description: values.description,
            verified_user: values.verified_user,
            active_professional: values.active_professional
          }
        });
      } else {
        alert('Ops! Confira se os campos: ' + errors + ', ficaram vazios!');
        errors = [];
      }

      if (ref !== null) {
        alert(
          'Profissional ( ' + values.name + ' ), foi atualizado com sucesso!'
        );
        restartFields();
        return null;
      }
    } catch (error) {
      alert(error);
    }
  }

  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <form autoComplete="off" onSubmit={reformProfessional}>
        <CardHeader
          subheader="Os campos com * são obrigatórios"
          title={`Atualização do Profissional: ${user.name}`}
        />
        <Divider />
        <CardContent>
          <Grid container spacing={3}>
            <Grid item md={4} xs={12}>
              <Typography className={classes.label}>
                Endereço Local *
              </Typography>
              <TextField
                fullWidth
                helperText="Este campo é obrigatório"
                margin="dense"
                name="address"
                onChange={handleChange}
                required
                value={values.address}
                variant="outlined"
              />
            </Grid>
            <Grid item md={4} xs={12}>
              <Typography className={classes.label}>Número do CEP</Typography>
              <TextField
                helperText="Este campo é obrigatório"
                fullWidth
                margin="dense"
                name="cep"
                onChange={handleChange}
                type="number"
                value={values.cep}
                variant="outlined"
              />
            </Grid>
            <Grid item md={4} xs={12}>
              <Typography className={classes.label}>Estado *</Typography>
              <TextField
                fullWidth
                helperText="Este campo é obrigatório"
                margin="dense"
                name="state"
                onChange={handleChange}
                SelectProps={{ native: true }}
                value={values.state}
                select
                variant="outlined">
                {states.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </TextField>
            </Grid>
            <Grid item md={4} xs={12}>
              <Typography className={classes.label}>Cidade *</Typography>
              <TextField
                fullWidth
                helperText="Este campo é obrigatório"
                required
                margin="dense"
                name="city"
                onChange={handleChange}
                value={values.city}
                variant="outlined"
              />
            </Grid>
            <Grid item md={4} xs={12}>
              <Typography className={classes.label}>Telefone *</Typography>
              <TextField
                fullWidth
                helperText="Este campo é obrigatório"
                margin="dense"
                name="contact"
                onChange={handleChange}
                required
                value={values.contact}
                variant="outlined"
              />
            </Grid>
            <Grid item md={4} xs={12}>
              <Typography className={classes.label}>Email</Typography>
              <TextField
                fullWidth
                margin="dense"
                name="email"
                onChange={handleChange}
                value={values.email}
                variant="outlined"
              />
            </Grid>
            <Grid item md={4} xs={12}>
              <Typography className={classes.label}>
                Função Profissional *
              </Typography>
              <TextField
                fullWidth
                helperText="Este campo é obrigatório"
                margin="dense"
                required
                name="sub_category_id"
                onChange={handleChange}
                select
                SelectProps={{ native: true }}
                value={values.sub_category_id}
                variant="outlined">
                {allSubCategories.map((sub) => (
                  <option key={sub.uid} value={sub.uid}>
                    {sub.name}
                  </option>
                ))}
              </TextField>
            </Grid>
            <Grid item md={4} xs={12}>
              <Typography className={classes.label}>CPF</Typography>
              <TextField
                fullWidth
                margin="dense"
                name="cpf"
                onChange={handleChange}
                value={values.cpf}
                variant="outlined"
              />
            </Grid>
            <Grid item md={4} xs={12}>
              <Typography className={classes.label}>CNPJ</Typography>
              <TextField
                fullWidth
                margin="dense"
                name="cnpj"
                onChange={handleChange}
                value={values.cnpj}
                variant="outlined"
              />
            </Grid>
            <Grid item md={4} xs={12}>
              <Typography className={classes.label}>Linkedin</Typography>
              <TextField
                fullWidth
                margin="dense"
                name="linkedin"
                onChange={handleChange}
                value={values.linkedin}
                variant="outlined"
              />
            </Grid>
            <Grid item md={4} xs={12}>
              <Typography align="center" className={classes.label}>
                Instagram
              </Typography>
              <TextField
                fullWidth
                margin="dense"
                name="instagram"
                onChange={handleChange}
                value={values.instagram}
                variant="outlined"
              />
            </Grid>
            <Grid item md={4} xs={12}>
              <Typography align="center" className={classes.label}>
                Facebook
              </Typography>
              <TextField
                fullWidth
                margin="dense"
                name="facebook"
                onChange={handleChange}
                value={values.facebook}
                variant="outlined"
              />
            </Grid>
            <Grid item md={4} xs={12}>
              <Typography align="center" className={classes.label}>
                Website
              </Typography>
              <TextField
                fullWidth
                margin="dense"
                name="website"
                onChange={handleChange}
                value={values.website}
                variant="outlined"
              />
            </Grid>
            <Grid item md={12} xs={12}>
              <Typography align="center" className={classes.label}>
                Descrição
              </Typography>
              <TextField
                fullWidth
                multiline
                margin="dense"
                name="description"
                onChange={handleChange}
                value={values.description}
                variant="outlined"
              />
            </Grid>
            <Grid className={classes.checkbox} item md={4} xs={4}>
              <Checkbox
                checked={values.account_active}
                required
                name="account_active"
                color="primary"
                onChange={handleCheckboxChange}
                value={values.account_active}
                variant="outlined"
              />
              <Typography className={classes.active}>Conta Ativa *</Typography>
            </Grid>
            <Grid className={classes.checkbox} item md={4} xs={4}>
              <Checkbox
                checked={values.verified_user}
                required
                name="verified_user"
                color="primary"
                onChange={handleCheckboxChange}
                value={values.verified_user}
                variant="outlined"
              />
              <Typography className={classes.active}>
                Usuário Verificado *
              </Typography>
            </Grid>
            <Grid className={classes.checkbox} item md={4} xs={4}>
              <Checkbox
                checked={values.active_professional}
                required
                name="active_professional"
                color="primary"
                onChange={handleCheckboxChange}
                value={values.active_professional}
                variant="outlined"
              />
              <Typography className={classes.active}>
                Profissional Ativo *
              </Typography>
            </Grid>
          </Grid>
        </CardContent>
        <Divider />
        <CardActions>
          <Button
            color="primary"
            onClick={reformProfessional}
            variant="contained">
            <RouterLink className={classes.link} to="/profissionais">
              Atualizar
            </RouterLink>
          </Button>
        </CardActions>
      </form>
    </Card>
  );
};

UpdateForm.propTypes = {
  className: PropTypes.string
};

export default UpdateForm;
