import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import {
  CardContent,
  CardActions,
  Grid,
  Button,
  TextField,
  Typography,
  FormControl
} from '@material-ui/core';

import { useSubscription, useMutation } from '@apollo/client';

import { ADD_USER } from 'data/mutations/mutations';
import { GETTING_SUB_CATEGORIES } from 'data/subscriptions/subscriptions';

const useStyles = makeStyles(() => ({
  root: {
    borderRadius: '10px'
  },
  label: {
    color: '#122259',
    fontWeight: 500
  },
  formFooter: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  button: {
    background: '#F8D36D',
    '&:hover': {
      background: '#F9C022'
    }
  },
  link: {
    color: '#fff'
  }
}));

const RegisterForm = (props) => {
  const { className } = props;

  const history = useHistory();

  const [addUser] = useMutation(ADD_USER);
  const { data: subCategories } = useSubscription(GETTING_SUB_CATEGORIES);

  const [allSubCategories, setAllSubCategories] = useState([
    {
      name: 'Selecionar Função Profissional'
    }
  ]);

  useEffect(() => {
    if (subCategories) {
      setAllSubCategories((allSubCategories) => [
        ...allSubCategories,
        ...subCategories.hopy_SubCategory
      ]);
    }
  }, [subCategories]);

  const classes = useStyles();

  let errors = [];

  const [values, setValues] = useState({
    name: '',
    address: '',
    city: 'João Pessoa',
    state: 'Paraíba',
    cep: '',
    contact: '',
    account_active: false,
    sub_category_id: '',
    email: '',
    cpf: '',
    cnpj: '',
    linkedin: '',
    instagram: '',
    facebook: '',
    website: '',
    description: '',
    verified_user: false,
    active_professional: false
  });

  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
  };

  const states = [
    {
      value: 'paraiba',
      label: 'Paraíba'
    }
  ];

  function restartFields() {
    setValues({
      name: '',
      address: '',
      city: 'João Pessoa',
      state: 'Paraíba',
      cep: '',
      contact: '',
      account_active: false,
      sub_category_id: '',
      email: '',
      cpf: '',
      cnpj: '',
      linkedin: '',
      instagram: '',
      facebook: '',
      website: '',
      description: '',
      verified_user: false,
      active_professional: false
    });
  }

  function checkRegisterForm() {
    if (values.name === '') errors.push('Nome');
    if (values.address === '') errors.push('Endereço');
    if (values.city === '') errors.push('Cidade');
    if (values.state === '') errors.push('Estado');
    if (values.contact === '') errors.push('Telefone');
    if (values.sub_category_id === '') errors.push('Função Profissional');

    if (errors.length !== 0) return false;

    return true;
  }

  function navigationToNewPage(event) {
    window.location.href = "https://profissionais.hopy.app/";
  }

  async function registerNewProfessional(event) {
    try {
      event.preventDefault();
      if (checkRegisterForm() === true) {
        addUser({
          variables: {
            name: values.name,
            address: values.address,
            cep: values.cep,
            city: values.city,
            state: values.state,
            contact: values.contact,
            account_active: true,
            sub_category_id: values.sub_category_id,
            email: values.email,
            cpf: values.cpf,
            cnpj: values.cnpj,
            linkedin: values.linkedin,
            instagram: values.instagram,
            facebook: values.facebook,
            website: values.website,
            description: values.description,
            verified_user: values.verified_user,
            active_professional: values.active_professional
          }
        });
        restartFields();
        history.push('/obrigado');
      } else {
        alert('Ops! Confira se os campos: ' + errors + ', ficaram vazios!');
        errors = [];
      }
    } catch (error) {
      alert(error);
    }
  }

  return (
    <FormControl
      className={clsx(classes.root, className)}
      autoComplete="off"
      onSubmit={registerNewProfessional}>
      <CardContent>
        {/* <Grid container spacing={3}>
          <Grid item md={6} xs={12}>
            <Typography align="center" className={classes.label}>
              Nome Completo *
            </Typography>
            <TextField
              fullWidth
              helperText="Este campo é obrigatório"
              margin="dense"
              name="name"
              onChange={handleChange}
              required
              value={values.name}
              variant="outlined"
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <Typography align="center" className={classes.label}>
              Endereço Local *
            </Typography>
            <TextField
              autoComplete="nope"
              fullWidth
              helperText="Este campo é obrigatório"
              margin="dense"
              name="address"
              onChange={handleChange}
              required
              value={values.address}
              variant="outlined"
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <Typography align="center" className={classes.label}>
              Estado *
            </Typography>
            <TextField
              fullWidth
              helperText="Este campo é obrigatório"
              margin="dense"
              name="state"
              onChange={handleChange}
              SelectProps={{ native: true }}
              value={values.state}
              select
              variant="outlined">
              {states.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </TextField>
          </Grid>
          <Grid item md={6} xs={12}>
            <Typography align="center" className={classes.label}>
              Cidade *
            </Typography>
            <TextField
              fullWidth
              helperText="Este campo é obrigatório"
              required
              margin="dense"
              name="city"
              onChange={handleChange}
              value={values.city}
              variant="outlined"
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <Typography align="center" className={classes.label}>
              Número do CEP
            </Typography>
            <TextField
              autoComplete="nope"
              fullWidth
              margin="dense"
              name="cep"
              onChange={handleChange}
              type="number"
              value={values.cep}
              variant="outlined"
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <Typography align="center" className={classes.label}>
              Telefone *
            </Typography>
            <TextField
              fullWidth
              helperText="Este campo é obrigatório. Formato: 83912345678"
              margin="dense"
              name="contact"
              type="number"
              onChange={handleChange}
              required
              value={values.contact}
              variant="outlined"
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <Typography align="center" className={classes.label}>
              Função Profissional *
            </Typography>
            <TextField
              fullWidth
              helperText="Este campo é obrigatório"
              margin="dense"
              required
              name="sub_category_id"
              onChange={handleChange}
              select
              SelectProps={{ native: true }}
              value={values.sub_category_id}
              variant="outlined">
              {allSubCategories.map((sub) => (
                <option key={sub.name} value={sub.uid}>
                  {sub.name}
                </option>
              ))}
            </TextField>
          </Grid>
          <Grid item md={6} xs={12}>
            <Typography align="center" className={classes.label}>
              Email
            </Typography>
            <TextField
              fullWidth
              margin="dense"
              name="email"
              onChange={handleChange}
              value={values.email}
              variant="outlined"
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <Typography align="center" className={classes.label}>
              CPF
            </Typography>
            <TextField
              fullWidth
              margin="dense"
              name="cpf"
              type="number"
              onChange={handleChange}
              value={values.cpf}
              variant="outlined"
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <Typography align="center" className={classes.label}>
              CNPJ
            </Typography>
            <TextField
              fullWidth
              margin="dense"
              name="cnpj"
              onChange={handleChange}
              value={values.cnpj}
              variant="outlined"
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <Typography align="center" className={classes.label}>
              Linkedin
            </Typography>
            <TextField
              fullWidth
              margin="dense"
              name="linkedin"
              onChange={handleChange}
              value={values.linkedin}
              variant="outlined"
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <Typography align="center" className={classes.label}>
              Instagram
            </Typography>
            <TextField
              fullWidth
              margin="dense"
              name="instagram"
              onChange={handleChange}
              value={values.instagram}
              variant="outlined"
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <Typography align="center" className={classes.label}>
              Facebook
            </Typography>
            <TextField
              fullWidth
              margin="dense"
              name="facebook"
              onChange={handleChange}
              value={values.facebook}
              variant="outlined"
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <Typography align="center" className={classes.label}>
              Website
            </Typography>
            <TextField
              fullWidth
              margin="dense"
              name="website"
              onChange={handleChange}
              value={values.website}
              variant="outlined"
            />
          </Grid>
          <Grid item md={12} xs={12}>
            <Typography align="center" className={classes.label}>
              Descrição
            </Typography>
            <TextField
              fullWidth
              multiline
              margin="dense"
              name="description"
              onChange={handleChange}
              value={values.description}
              variant="outlined"
            />
          </Grid>
        </Grid> */}
      </CardContent>
      <CardActions className={classes.formFooter}>
        <Button
          className={classes.button}
          onClick={navigationToNewPage}
          size='large'
          variant="contained">
          ACESSAR AGORA
        </Button>
      </CardActions>
    </FormControl>
  );
};

RegisterForm.propTypes = {
  className: PropTypes.string
};

export default RegisterForm;
