import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Grid, Button, TextField, Typography, Paper } from '@material-ui/core';

import firebase from 'config/firebase.js';
import hopyImage from '../../assets/hopy_icon_login.png';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    height: '100%',
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    [theme.breakpoints.up(600 + theme.spacing(6))]: {
      width: 600,
      marginLeft: 'auto',
      marginRight: 'auto'
    }
  },
  grid: {
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center'
  },
  quoteContainer: {
    [theme.breakpoints.down('md')]: {
      display: 'none'
    }
  },
  paper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px ${theme.spacing(
      2
    )}px`,
    boxShadow: '0px 10px 30px rgba(0, 0, 0, 0.3)'
  },
  quote: {
    backgroundColor: theme.palette.neutral,
    height: '100%',
    display: 'flex',
    backgroundImage: 'url(/images/auth.jpg)',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center'
  },
  quoteInner: {
    textAlign: 'center',
    flexBasis: '600px'
  },
  quoteText: {
    color: theme.palette.white,
    fontWeight: 300
  },
  name: {
    marginTop: theme.spacing(5),
    color: theme.palette.white
  },
  bio: {
    color: theme.palette.white
  },
  contentContainer: {},
  content: {
    display: 'flex',
    flexDirection: 'column'
  },
  contentHeader: {
    display: 'flex',
    alignItems: 'center',
    paddingTop: theme.spacing(2),
    paddingBototm: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  },
  logoImage: {
    margin: '1em',
    display: 'flex',
    justifyContent: 'center'
  },
  contentBody: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  form: {
    flexBasis: 100,
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1)
      // paddingBottom: theme.spacing(2)
    }
  },
  title: {
    textAlign: 'center',
    color: '#68AF27'
  },
  socialButtons: {
    marginTop: theme.spacing(3)
  },
  socialIcon: {
    marginRight: theme.spacing(1)
  },
  sugestion: {
    marginTop: theme.spacing(2)
  },
  textField: {
    marginTop: theme.spacing(1)
  },
  signInButton: {
    backgroundColor: '#68AF27',
    margin: theme.spacing(2, 0),
    '&:hover': {
      backgroundColor: '#00AF00'
    }
  },
  label: {
    fontWeight: 500,
    color: '#68AF27',
    fontSize: '16px',
    marginBottom: 0,
    marginTop: theme.spacing(2)
  }
}));

const SignIn = (props) => {
  const { history } = props;

  const classes = useStyles();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  async function login() {
    try {
      await firebase.login(email, password);
      history.replace('/home');
    } catch (error) {
      alert(error.message);
    }
  }

  return (
    <div className={classes.root}>
      <Grid className={classes.grid} container>
        <Grid className={classes.content} item lg={7} xs={12}>
          <div className={classes.contentBody}>
            <Paper className={classes.paper}>
              <img className={classes.logoImage} src={hopyImage} alt="Hopy" />
              <form
                className={classes.form}
                onSubmit={(e) => e.preventDefault() && false}>
                <Typography className={classes.title} variant="h2">
                  Área Administrativa
                </Typography>
                <Grid
                  className={classes.socialButtons}
                  container
                  spacing={2}></Grid>
                <Typography className={classes.label}>Email</Typography>
                <TextField
                  className={classes.textField}
                  // error={hasError('email')}
                  fullWidth
                  // helperText={
                  //   hasError('email') ? formState.errors.email[0] : null
                  // }
                  // label="Email"
                  name="email"
                  onChange={(e) => setEmail(e.target.value)}
                  type="text"
                  value={email}
                  variant="outlined"
                />
                <Typography className={classes.label}>Senha</Typography>
                <TextField
                  className={classes.textField}
                  // error={hasError('password')}
                  fullWidth
                  // helperText={
                  //   hasError('password') ? formState.errors.password[0] : null
                  // }
                  // label="Password"
                  name="password"
                  onChange={(e) => setPassword(e.target.value)}
                  type="password"
                  value={password}
                  variant="outlined"
                />
                <Button
                  className={classes.signInButton}
                  color="primary"
                  // disabled={!formState.isValid}
                  fullWidth
                  size="large"
                  type="submit"
                  variant="contained"
                  onClick={login}>
                  Acessar
                </Button>
              </form>
            </Paper>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

SignIn.propTypes = {
  history: PropTypes.object
};

export default withRouter(SignIn);
