import React from 'react';
import './style/style.min.css';

import hopy from '../../assets/hopy.svg';

const AfterRegister = () => {
  return (
    <div className="container">
      <img src={hopy} alt="Hopy" />
      <footer>
        Obrigado por se cadastrar na Hopy, seja bem vindo(a)!
      </footer>
    </div>
  );
};

export default AfterRegister;
