import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/styles';
import { Divider, Drawer } from '@material-ui/core';

import WorkIcon from '@material-ui/icons/Work';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import ListIcon from '@material-ui/icons/List';
import DashboadIcon from '@material-ui/icons/Dashboard';
import People from '@material-ui/icons/People';
import AnnouncementIcon from '@material-ui/icons/Announcement';
import GradeIcon from '@material-ui/icons/Grade';

import { Profile, SidebarNav } from './components';

const useStyles = makeStyles((theme) => ({
  drawer: {
    width: 240,
    [theme.breakpoints.up('lg')]: {
      marginTop: 64,
      height: 'calc(100% - 64px)'
    }
  },
  root: {
    backgroundColor: theme.palette.white,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    padding: theme.spacing(2)
  },
  divider: {
    margin: theme.spacing(2, 0)
  },
  nav: {
    marginBottom: theme.spacing(2)
  }
}));

const Sidebar = (props) => {
  const { open, variant, onClose, className, ...rest } = props;

  const classes = useStyles();

  const pages = [
    {
      title: 'Início',
      href: '/home',
      icon: <DashboadIcon />
    },
    {
      title: 'Categoria',
      href: '/categoria',
      icon: <ListIcon />
    },
    {
      title: 'Função Profissional',
      href: '/funcao-profissional',
      icon: <WorkIcon />
    },
    {
      title: 'Cadastrar Profissionais',
      href: '/profissoes',
      icon: <AssignmentIndIcon />
    },
    {
      title: 'Listar Profissionais',
      href: '/profissionais',
      icon: <People />
    },
    {
      title: 'Listar Anúncios',
      href: '/anuncios',
      icon: <AnnouncementIcon />
    },
    {
      title: 'Listar Avaliações',
      href: '/avaliacoes',
      icon: <GradeIcon />
    }
  ];

  return (
    <Drawer
      anchor="left"
      classes={{ paper: classes.drawer }}
      onClose={onClose}
      open={open}
      variant={variant}>
      <div {...rest} className={clsx(classes.root, className)}>
        <Profile />
        <Divider className={classes.divider} />
        <SidebarNav className={classes.nav} pages={pages} />
        {/* <UpgradePlan /> */}
      </div>
    </Drawer>
  );
};

Sidebar.propTypes = {
  className: PropTypes.string,
  onClose: PropTypes.func,
  open: PropTypes.bool.isRequired,
  variant: PropTypes.string.isRequired
};

export default Sidebar;
