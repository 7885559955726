import React, { useState, useEffect } from 'react';
import { useSubscription, useMutation } from '@apollo/client';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardActions,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TablePagination,
  CardHeader
} from '@material-ui/core';
import { ApprovedButton } from '../../components';

import {
  GETTING_RATINGS,
  GETTING_PROFESSIONALS_NAMES
} from 'data/subscriptions/subscriptions';
import { UPDATE_RATING } from 'data/mutations/mutations';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4),
    width: '100%'
  },
  content: {
    padding: 0,
    width: '100%'
  },
  inner: {
    width: '100%'
  },
  infoContainer: {
    maxHeight: '71px',
    maxWidth: '250px'
  },
  actions: {
    justifyContent: 'flex-end'
  },
  link: {
    maxWidth: '200px',
    margin: 0
  },
  title: {
    backgroundColor: '#68AF27',
    justifyContent: 'center'
  }
}));

const RatingList = (props) => {
  const { className, ...rest } = props;

  const classes = useStyles();

  const [updateRating] = useMutation(UPDATE_RATING);
  const { data: rawRatings } = useSubscription(GETTING_RATINGS);
  const { data: rawProfessionals } = useSubscription(
    GETTING_PROFESSIONALS_NAMES
  );

  const [ratings, setRatings] = useState([]);
  const [professionals, setProfessionals] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);

  useEffect(() => {
    if (rawRatings && rawProfessionals) {
      setRatings(rawRatings.hopy_Ranking);
      setProfessionals(rawProfessionals.hopy_User);
    }
  }, [rawRatings, rawProfessionals]);

  const handlePageChange = (event, page) => {
    setPage(page);
  };

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(event.target.value);
    setPage(0);
  };

  return (
    <div className={classes.root}>
      <Card {...rest}>
        <CardHeader className={classes.title} title="Tabela de Avaliações" />
        <CardContent className={classes.content}>
          <PerfectScrollbar>
            <div className={classes.inner}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Profissional</TableCell>
                    <TableCell>Usuário</TableCell>
                    <TableCell>Descrição</TableCell>
                    <TableCell>Avaliação</TableCell>
                    <TableCell>Aprovação</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody className={classes.tableBody}>
                  {ratings.slice(0, rowsPerPage).map((rating) => (
                    <TableRow
                      key={rating.id}
                      className={classes.tableRow}
                      hover>
                      <TableCell className={classes.infoContainer}>
                        {professionals.map((professional) => {
                          if (professional.uid === rating.uid_prof) {
                            return professional.name;
                          }
                          return null;
                        })}
                      </TableCell>
                      <TableCell className={classes.infoContainer}>
                        {rating.user_name}
                      </TableCell>
                      <TableCell className={classes.infoContainer}>
                        {rating.description}
                      </TableCell>
                      <TableCell className={classes.infoContainer}>
                        {rating.rating}
                      </TableCell>
                      <TableCell>
                        <ApprovedButton
                          onClick={() => {
                            updateRating({
                              variables: {
                                id: rating.id,
                                check: !rating.check
                              }
                            });
                          }}
                          checked={rating.check}
                          approveText="Aprovar essa avaliação?"
                          disapproveText="Desaprovar essa avaliação?"
                        />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </div>
          </PerfectScrollbar>
        </CardContent>
        <CardActions className={classes.actions}>
          <TablePagination
            rowsPerPageOptions={[5, 10, 20, 50, 100, 200, 500]}
            component="div"
            count={ratings.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handlePageChange}
            onChangeRowsPerPage={handleRowsPerPageChange}
          />
        </CardActions>
      </Card>
    </div>
  );
};

RatingList.propTypes = {
  className: PropTypes.string
};

export default RatingList;
