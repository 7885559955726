import React from 'react';
import { Switch, Redirect } from 'react-router-dom';

import { RouteWithLayout } from './components';
import { Main as MainLayout, Minimal as MinimalLayout } from './layouts';

import {
  Dashboard as DashboardView,
  ProfessionalManagement as ProfessionalManagementView,
  ProfessionalRegister as ProfessionalRegisterView,
  ProfessionalUpdate as ProfessionalUpdateView,
  UserList as UserListView,
  Category as CategoryView,
  Settings as SettingsView,
  SignUp as SignUpView,
  SignIn as SignInView,
  NotFound as NotFoundView,
  Function as FunctionView,
  AfterRegister as AfterRegisterView,
  AnnounceList as AnnounceListView,
  AnnounceUpdate as AnnounceUpdateView,
  RatingList as RatingListView
} from './views';

const Routes = () => {
  return (
    <Switch>
      <Redirect exact from="/" to="/sign-in" />
      <RouteWithLayout
        component={DashboardView}
        exact
        layout={MainLayout}
        path="/home"
      />
      <RouteWithLayout
        component={ProfessionalManagementView}
        exact
        layout={MainLayout}
        path="/profissoes"
      />
      <RouteWithLayout
        component={UserListView}
        exact
        layout={MainLayout}
        path="/profissionais"
      />
      <RouteWithLayout
        component={CategoryView}
        exact
        layout={MainLayout}
        path="/categoria"
      />
      <RouteWithLayout
        component={SettingsView}
        exact
        layout={MainLayout}
        path="/settings"
      />
      <RouteWithLayout
        component={FunctionView}
        exact
        layout={MainLayout}
        path="/funcao-profissional"
      />
      <RouteWithLayout
        component={ProfessionalUpdateView}
        exact
        layout={MainLayout}
        path="/atualizar-profissional"
      />
      <RouteWithLayout
        component={AnnounceListView}
        exact
        layout={MainLayout}
        path="/anuncios"
      />
      <RouteWithLayout
        component={AnnounceUpdateView}
        exact
        layout={MainLayout}
        path="/atualizar-anuncios"
      />
      <RouteWithLayout
        component={RatingListView}
        exact
        layout={MainLayout}
        path="/avaliacoes"
      />
      <RouteWithLayout
        component={ProfessionalRegisterView}
        exact
        layout={MinimalLayout}
        path="/cadastro-profissional"
      />
      <RouteWithLayout
        component={AfterRegisterView}
        exact
        layout={MinimalLayout}
        path="/obrigado"
      />
      <RouteWithLayout
        component={SignUpView}
        exact
        layout={MinimalLayout}
        path="/somente-admin-cadastro-privado"
      />
      <RouteWithLayout
        component={SignInView}
        exact
        layout={MinimalLayout}
        path="/sign-in"
      />
      <RouteWithLayout
        component={NotFoundView}
        exact
        layout={MinimalLayout}
        path="/not-found"
      />
      <Redirect to="/not-found" />
    </Switch>
  );
};

export default Routes;
