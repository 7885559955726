import React from 'react';

import './style.min.css';

import arrow from 'assets/arrow.svg';
import main_circle from 'assets/main_circle.svg';
import hopyLogo from 'assets/hopy.svg';
import chef from 'assets/chef.svg';
import engineer from 'assets/engineer.svg';
import artist from 'assets/artist.svg';
import worker from 'assets/worker.svg';
import RegisterForm from '../RegisterForm';
import { Grid } from '@material-ui/core';

const Header = () => {
  return (
    <Grid container className="header">
      <img className="mainCircle" src={main_circle} alt="Main Circle" />
      <img className="logo" src={hopyLogo} alt="Hopy" />
      <p className="headText">
        Deseja trabalhar
        <br />
        com a Hopy?
      </p>
      <div className="subHeader">
        <div className="subImages">
          <img className="engineer" src={engineer} alt="Engineer" />
          <img className="chef" src={chef} alt="Chef" />
        </div>
        <p className="subText">
          Queremos alavancar seus trabalhos, para isso, vamos lhe indicar para
          diversas pessoas. O que você acha? <br /> <span>Vamos nessa?</span>
        </p>
        <div className="subImages">
          <img className="artist" src={artist} alt="Artist" />
          <img className="worker" src={worker} alt="Worker" />
        </div>
      </div>
      <main className="main">
        <div className="main-header">
          {/* <div className="header_left">
            <p className="service_title">
              TODOS OS SERVIÇOS <br /> 100% <span>GRATUITOS</span>
            </p>
            <p className="service_text">
              Sim, é isso mesmo que você leu. Nossos serviços, tanto para os
              profissionais, como para os usuários, são{' '}
              <span>totalmente gratuitos</span>.
              <br /> <br /> Nossa missão vai ao encontro da facilidade para as
              pessoas resolverem seus problemas contratando competentes
              profissionais. Nossa meta é destacar esses BONS profissionais
              dando a eles a possibilidade de encontrar novos trabalhos. Que
              fique bem claro, nosso foco é ter em nossa plataforma apenas os
              bons profissionais, aqueles que realmente procuram trabalhar
              honestamente e de forma justa.
            </p>
          </div> */}
          {/* <div className="header_right">
            <p className="contract_title">
              CONTRATE E OU <br /> SEJA <span>CONTRATADO</span>
            </p>
            <p className="contract_text">
              Mesmo você optando por ativar o seu perfil profissional em nossa
              ferramenta, você também poderá navegar em nosso aplicativo a
              procura de profissionais para lhe atender sob qualquer
              necessidade.
            </p>
            <p className="notification_title">
              RECEBA <br /> <span>NOTIFICAÇÕES</span>
            </p>
            <p className="notification_text">
              Mesmo você optando por ativar o seu perfil profissional em nossa
              ferramenta, você também poderá navegar em nosso aplicativo a
              procura de profissionais para lhe atender sob qualquer
              necessidade.
            </p>
          </div> */}
        </div>
        <div className="headerFooter">
          <img src={arrow} className="arrow_down" alt="Arrow" />
          <p className="footer_text">
            ATENÇÃO <br />
            O FORMULÁRIO DE CADASTRO MUDOU E ESTÁ DISPONÍVEL EM OUTRA PÁGINA <br />
            <span>CLIQUE NO BOTÃO PARA PODER ACESSAR</span>
          </p>
          <img src={arrow} className="arrow_down" alt="Arrow" />
        </div>
        <RegisterForm className="registerForm" />
      </main>
    </Grid>
  );
};

export default Header;
