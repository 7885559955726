import { gql } from '@apollo/client';

export const GET_PROFESSIONALS = gql`
  query {
    hopy_User {
      uid
      name
    }
  }
`;

export const GET_CATEGORIES = gql`
  query {
    hopy_Category {
      uid
      name
      description
    }
  }
`;

export const GET_SUB_CATEGORIES = gql`
  query {
    hopy_SubCategory {
      uid
      name
      description
      category_id
    }
  }
`;

export const GET_PROFESSIONAL_BY_NAME = gql`
  query($search: String!) {
    hopy_User(where: {name: {_ilike: $search}}) {
      uid
      name
      email
      address
      cpf
      contact
      active_professional
      verified_user
      account_active
      token
      cnpj
      website
      facebook
      instagram
      linkedin
      description
      sub_category_id
      city
      state
      cep
    }
  }
`