import React, { useState, useEffect, useCallback } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
  Grid,
  Button,
  TextField,
  Checkbox,
  Typography
} from '@material-ui/core';

import { useSubscription, useMutation } from '@apollo/client';

import { ADD_USER } from 'data/mutations/mutations';
import { GETTING_SUB_CATEGORIES } from 'data/subscriptions/subscriptions';

const useStyles = makeStyles((theme) => ({
  root: {},
  checkbox: {
    display: 'flex',
    alignItems: 'flex-end',
    paddingLeft: theme.spacing(2),
    marginBottom: theme.spacing(1),
    color: '#68AF27'
  },
  label: {
    color: '#68AF27',
    fontWeight: 500
  },
  active: {
    color: '#68AF27',
    fontWeight: 500,
    paddingBottom: theme.spacing(1.2)
  }
}));

const AccountDetails = (props) => {
  const { className, ...rest } = props;

  const [addProfessional] = useMutation(ADD_USER);
  const { data: subCategories } = useSubscription(GETTING_SUB_CATEGORIES);

  const [allSubCategories, setAllSubCategories] = useState([
    {
      name: 'Selecionar Função Profissional'
    }
  ]);

  useEffect(() => {
    if (subCategories) {
      setAllSubCategories((allSubCategories) => [
        ...allSubCategories,
        ...subCategories.hopy_SubCategory
      ]);
    }
  }, [subCategories]);

  const classes = useStyles();

  let errors = [];

  const [values, setValues] = useState({
    name: '',
    address: '',
    city: 'João Pessoa',
    state: 'Paraíba',
    cep: '',
    contact: '',
    account_active: false,
    sub_category_id: '',
    email: '',
    cpf: '',
    cnpj: '',
    linkedin: '',
    instagram: '',
    facebook: '',
    website: '',
    description: '',
    verified_user: false,
    active_professional: false
  });

  const handleChange = useCallback(
    (event) => {
      setValues({
        ...values,
        [event.target.name]: event.target.value
      });
    },
    [values]
  );

  const handleCheckboxChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.checked
    });
  };

  const states = [
    {
      value: 'paraiba',
      label: 'Paraíba'
    }
  ];

  function restartFields() {
    setValues({
      name: '',
      address: '',
      city: 'João Pessoa',
      state: 'Paraíba',
      cep: '',
      contact: '',
      account_active: false,
      sub_category_id: '',
      email: '',
      cpf: '',
      cnpj: '',
      linkedin: '',
      instagram: '',
      facebook: '',
      website: '',
      description: '',
      verified_user: false,
      active_professional: false
    });
  }

  function checkRegisterForm() {
    if (values.name === '') errors.push('Nome');
    if (values.address === '') errors.push('Endereço');
    if (values.cep === '') errors.push('CEP');
    if (values.city === '') errors.push('Cidade');
    if (values.state === '') errors.push('Estado');
    if (values.contact === '') errors.push('Telefone');
    if (values.sub_category_id === '') errors.push('Função Profissional');

    if (errors.length !== 0) return false;

    return true;
  }

  async function registerNewProfessional(event) {
    try {
      event.preventDefault();
      let ref = null;
      if (checkRegisterForm() === true) {
        ref = addProfessional({
          variables: {
            name: values.name,
            address: values.address,
            cep: values.cep,
            city: values.city,
            state: values.state,
            contact: values.contact,
            account_active: values.account_active,
            sub_category_id: values.sub_category_id,
            email: values.email,
            cpf: values.cpf,
            cnpj: values.cnpj,
            linkedin: values.linkedin,
            instagram: values.instagram,
            facebook: values.facebook,
            website: values.website,
            description: values.description,
            verified_user: values.verified_user,
            active_professional: values.active_professional
          }
        });
      } else {
        alert('Ops! Confira se os campos: ' + errors + ', ficaram vazios!');
        errors = [];
      }

      if (ref !== null) {
        alert(
          'Profissional ( ' + values.name + ' ), foi cadastrado com sucesso!'
        );
        restartFields();
        return null;
      }
    } catch (error) {
      alert(error);
    }
  }

  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <form autoComplete="off" onSubmit={registerNewProfessional}>
        <CardHeader
          subheader="Os campos com * são obrigatórios"
          title="Cadastro de Profissional"
        />
        <Divider />
        <CardContent>
          <Grid container spacing={3}>
            <Grid item md={6} xs={12}>
              <Typography align="center" className={classes.label}>
                Nome Completo *
              </Typography>
              <TextField
                fullWidth
                helperText="Este campo é obrigatório"
                margin="dense"
                name="name"
                onChange={handleChange}
                required
                value={values.name}
                variant="outlined"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <Typography align="center" className={classes.label}>
                Endereço Local *
              </Typography>
              <TextField
                autoComplete="nope"
                fullWidth
                helperText="Este campo é obrigatório"
                margin="dense"
                name="address"
                onChange={handleChange}
                required
                value={values.address}
                variant="outlined"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <Typography align="center" className={classes.label}>
                Número do CEP *
              </Typography>
              <TextField
                autoComplete="nope"
                helperText="Este campo é obrigatório"
                required
                fullWidth
                margin="dense"
                name="cep"
                onChange={handleChange}
                type="number"
                value={values.cep}
                variant="outlined"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <Typography align="center" className={classes.label}>
                Estado *
              </Typography>
              <TextField
                fullWidth
                helperText="Este campo é obrigatório"
                margin="dense"
                name="state"
                onChange={handleChange}
                SelectProps={{ native: true }}
                value={values.state}
                select
                variant="outlined">
                {states.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </TextField>
            </Grid>
            <Grid item md={6} xs={12}>
              <Typography align="center" className={classes.label}>
                Cidade *
              </Typography>
              <TextField
                fullWidth
                helperText="Este campo é obrigatório"
                required
                margin="dense"
                name="city"
                onChange={handleChange}
                value={values.city}
                variant="outlined"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <Typography align="center" className={classes.label}>
                Telefone *
              </Typography>
              <TextField
                fullWidth
                helperText="Este campo é obrigatório. Formato: 83912345678"
                margin="dense"
                name="contact"
                type="number"
                onChange={handleChange}
                required
                value={values.contact}
                variant="outlined"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <Typography align="center" className={classes.label}>
                Função Profissional *
              </Typography>
              <TextField
                fullWidth
                helperText="Este campo é obrigatório"
                margin="dense"
                required
                name="sub_category_id"
                onChange={handleChange}
                select
                SelectProps={{ native: true }}
                value={values.sub_category_id}
                variant="outlined">
                {allSubCategories.map((sub) => (
                  <option key={sub.name} value={sub.uid}>
                    {sub.name}
                  </option>
                ))}
              </TextField>
            </Grid>
            <Grid item md={6} xs={12}>
              <Typography align="center" className={classes.label}>
                Email
              </Typography>
              <TextField
                fullWidth
                margin="dense"
                name="email"
                onChange={handleChange}
                value={values.email}
                variant="outlined"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <Typography align="center" className={classes.label}>
                CPF
              </Typography>
              <TextField
                fullWidth
                margin="dense"
                name="cpf"
                type="number"
                onChange={handleChange}
                value={values.cpf}
                variant="outlined"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <Typography align="center" className={classes.label}>
                CNPJ
              </Typography>
              <TextField
                fullWidth
                margin="dense"
                name="cnpj"
                onChange={handleChange}
                value={values.cnpj}
                variant="outlined"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <Typography align="center" className={classes.label}>
                Linkedin
              </Typography>
              <TextField
                fullWidth
                margin="dense"
                name="linkedin"
                onChange={handleChange}
                value={values.linkedin}
                variant="outlined"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <Typography align="center" className={classes.label}>
                Instagram
              </Typography>
              <TextField
                fullWidth
                margin="dense"
                name="instagram"
                onChange={handleChange}
                value={values.instagram}
                variant="outlined"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <Typography align="center" className={classes.label}>
                Facebook
              </Typography>
              <TextField
                fullWidth
                margin="dense"
                name="facebook"
                onChange={handleChange}
                value={values.facebook}
                variant="outlined"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <Typography align="center" className={classes.label}>
                Website
              </Typography>
              <TextField
                fullWidth
                margin="dense"
                name="website"
                onChange={handleChange}
                value={values.website}
                variant="outlined"
              />
            </Grid>
            <Grid className={classes.checkbox} item md={4} xs={12}>
              <Checkbox
                required
                name="account_active"
                color="primary"
                onChange={handleCheckboxChange}
                value={values.account_active}
                variant="outlined"
              />
              <Typography className={classes.active}>Conta Ativa *</Typography>
            </Grid>
            <Grid className={classes.checkbox} item md={4} xs={12}>
              <Checkbox
                required
                name="verified_user"
                color="primary"
                onChange={handleCheckboxChange}
                value={values.verified_user}
                variant="outlined"
              />
              <Typography className={classes.active}>
                Usuário Verificado *
              </Typography>
            </Grid>
            <Grid className={classes.checkbox} item md={4} xs={12}>
              <Checkbox
                required
                name="active_professional"
                color="primary"
                onChange={handleCheckboxChange}
                value={values.active_professional}
                variant="outlined"
              />
              <Typography className={classes.active}>
                Profissional Ativo *
              </Typography>
            </Grid>
          </Grid>
        </CardContent>
        <Divider />
        <CardActions>
          <Button
            color="primary"
            onClick={registerNewProfessional}
            variant="contained">
            Cadastrar
          </Button>
        </CardActions>
      </form>
    </Card>
  );
};

AccountDetails.propTypes = {
  className: PropTypes.string
};

export default AccountDetails;
