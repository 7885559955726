import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Grid,
  Divider,
  Typography,
  Button,
  Input
} from '@material-ui/core';
import firebase from 'config/firebase.js';

const useStyles = makeStyles(() => ({
  root: {},
  item: {
    display: 'flex',
    flexDirection: 'column'
  },
  submitButton: {
    marginTop: '10px'
  },
  input: {
    '> button':  {
      color: 'red'
    }
  }
}));

const Photo = (props) => {
  const { className, ...rest } = props;

  const [photo, setPhoto] = useState([]);

  async function onSubmit() {
    try {
      await firebase.changeCurrentUserPhoto(photo[0]);
      alert('Foto modificada com sucesso!');
      return;
    } catch (error) {
      alert(error.message);
    }
  }

  const classes = useStyles();

  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <form>
        <CardHeader
          subheader="Nota: É necessário recarregar a página após trocar sua foto para que surja efeito"
          title="Foto de perfil"
        />
        <Divider />
        <CardContent>
          <Grid container spacing={6} wrap="wrap">
            <Grid className={classes.item} item md={8} sm={6} xs={12}>
              <Typography className={classes.label}>
                Escolha uma nova foto
              </Typography>
              <Input
                className={classes.input}
                fullWidth
                name="photo"
                onChange={(e) => setPhoto(e.target.files)}
                type="file"
                required
                disableUnderline
                color="primary"
              />
            </Grid>
          </Grid>
        </CardContent>
        <Divider />
        <CardActions>
          <Button color="primary" variant="outlined" onClick={onSubmit}>
            Alterar foto
          </Button>
        </CardActions>
      </form>
    </Card>
  );
};

Photo.propTypes = {
  className: PropTypes.string
};

export default Photo;
