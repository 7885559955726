import React, { useState, useEffect } from 'react';
import { useQuery } from '@apollo/client';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Card, CardContent, Grid, Typography } from '@material-ui/core';
// import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';

import { GET_PROFESSIONALS } from 'data/queries/queries';

const useStyles = makeStyles((theme) => ({
  root: {},
  content: {
    alignItems: 'center',
    display: 'flex'
  },
  title: {
    fontWeight: 700
  },
  avatar: {
    backgroundColor: theme.palette.success.main,
    height: 56,
    width: 56
  },
  icon: {
    height: 32,
    width: 32
  },
  difference: {
    marginTop: theme.spacing(2),
    display: 'flex',
    alignItems: 'center'
  },
  differenceIcon: {
    color: theme.palette.success.dark
  },
  differenceValue: {
    color: theme.palette.success.dark,
    marginRight: theme.spacing(1)
  },
  grid: {
    // display: 'flex'
  }
}));

const TotalUsers = (props) => {
  const { className, ...rest } = props;

  const { data } = useQuery(GET_PROFESSIONALS);

  const [totalUsers, setTotalUsers] = useState('');

  useEffect(() => {
    if (data) {
      setTotalUsers(data.hopy_User.length);
    }
  }, [data]);

  const classes = useStyles();

  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <CardContent>
        <Grid container justify="space-between">
          <Grid item className={classes.grid}>
            <Typography
              className={classes.title}
              color="textSecondary"
              gutterBottom
              variant="body2">
              TOTAL DE USUÁRIOS CADASTRADOS
            </Typography>
            <Typography variant="h3">{totalUsers}</Typography>
          </Grid>
          <Grid item>
            {/* <Avatar className={classes.avatar}>
              <PeopleIcon className={classes.icon} />
            </Avatar> */}
          </Grid>
        </Grid>
        {/* <div className={classes.difference}>
          <ArrowUpwardIcon className={classes.differenceIcon} />
          <Typography className={classes.differenceValue} variant="body2">
            16%
          </Typography>
          <Typography className={classes.caption} variant="caption">
            Desde o mês passado
          </Typography>
        </div> */}
      </CardContent>
    </Card>
  );
};

TotalUsers.propTypes = {
  className: PropTypes.string
};

export default TotalUsers;
