import React, { useState, useEffect } from 'react';
import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { Chart } from 'react-chartjs-2';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/styles';

import { chartjs } from './helpers';
import theme from './theme';
import 'react-perfect-scrollbar/dist/css/styles.css';
import './assets/scss/index.scss';
import Routes from './Routes.js';
import firebase from 'config/firebase.js';
import { CircularProgress } from '@material-ui/core';

const browserHistory = createBrowserHistory();

Chart.helpers.extend(Chart.elements.Rectangle.prototype, {
  draw: chartjs.draw
});

const useStyles = makeStyles((theme) => ({
  grid: {
    display: 'flex',
    height: '100vh',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center'
  },
  progress: {
    color: '68AF27',
    margin: 'auto'
  }
}));

const App = () => {
  const classes = useStyles();

  const [firebaseInitialized, setFirebaseInitialized] = useState(false);

  // const client = createApolloClient();

  useEffect(() => {
    firebase.isInitialized().then((val) => {
      setFirebaseInitialized(val);
    });
  });

  return firebaseInitialized !== false ? (
    // <ApolloProvider client={client}>
    <MuiThemeProvider theme={theme}>
      <Router history={browserHistory}>
        <Routes />
      </Router>
    </MuiThemeProvider>
  ) : (
    // </ApolloProvider>
    <div className={classes.grid} id="loader">
      <CircularProgress className={classes.progress} />
    </div>
  );
}

export default App;
