import React, { useState, useEffect } from 'react';
import { useSubscription, useMutation } from '@apollo/client';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
  Button,
  TextField,
  Typography
} from '@material-ui/core';

import { GETTING_CATEGORIES } from 'data/subscriptions/subscriptions';
import { ADD_SUB_CATEGORY } from 'data/mutations/mutations';

const useStyles = makeStyles(() => ({
  root: {},
  label: {
    color: '#68AF27',
    fontWeight: 500
  }
}));

const FormFields = (props) => {
  const { className, ...rest } = props;

  const [addSubCategory] = useMutation(ADD_SUB_CATEGORY);

  const { data: categories } = useSubscription(GETTING_CATEGORIES);

  const [allCategories, setAllCategories] = useState([
    {
      name: 'Selecionar Categoria',
      uid: ''
    }
  ]);

  useEffect(() => {
    if (categories) {
      setAllCategories((allCategories) => [
        ...allCategories,
        ...categories.hopy_Category
      ]);
    }
  }, [categories]);

  let errors = [];

  const classes = useStyles();

  const [values, setValues] = useState({
    name: '',
    description: '',
    category: ''
  });

  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
  };

  function restartFields() {
    setValues({
      name: '',
      description: '',
      category_id: allCategories[0].uid
    });
  }

  function checkCategoryForm() {
    if (values.name === '') errors.push('Nome da Especialidade');
    if (values.description === '') errors.push('Descrição');
    if (values.category_id === '') errors.push('Categoria');

    if (errors.length !== 0) return false;

    return true;
  }

  async function registerSubCategory(event) {
    try {
      event.preventDefault();
      let ref = null;
      if (checkCategoryForm()) {
        ref = addSubCategory({
          variables: {
            name: values.name,
            description: values.description,
            category_id: values.category_id
          }
        });
      } else {
        alert('Ops! Confira se os campos: ' + errors + ', ficaram vazios!');
        errors = [];
      }

      if (ref !== null) {
        alert('Função ( ' + values.name + ' ) Cadastrada com Sucesso!');
        restartFields();
        return null;
      }
    } catch (error) {
      alert(error);
    }
  }

  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <form>
        <CardHeader
          title="Cadastrar Função Profissional"
          onSubmit={(e) => e.preventDefault() && false}
        />
        <Divider />
        <CardContent>
          <Typography className={classes.label}>
            Nome da Função/Especialidade
          </Typography>
          <TextField
            fullWidth
            name="name"
            onChange={handleChange}
            required
            value={values.name}
            variant="outlined"
          />
          <Typography className={classes.label} style={{ marginTop: '1rem' }}>
            Descrição
          </Typography>
          <TextField
            fullWidth
            name="description"
            onChange={handleChange}
            required
            value={values.description}
            variant="outlined"
          />
          <Typography className={classes.label} style={{ marginTop: '1rem' }}>
            Categoria
          </Typography>
          <TextField
            fullWidth
            name="category_id"
            onChange={handleChange}
            required
            select
            SelectProps={{ native: true }}
            value={values.category_id}
            variant="outlined">
            {allCategories.map((cat, index) => (
              <option key={index} value={cat.uid}>
                {cat.name}
              </option>
            ))}
          </TextField>
        </CardContent>
        <Divider />
        <CardActions>
          <Button
            style={{ background: '#68AF27' }}
            color="primary"
            variant="contained"
            onClick={registerSubCategory}>
            Cadastrar
          </Button>
        </CardActions>
      </form>
    </Card>
  );
};

FormFields.propTypes = {
  className: PropTypes.string
};

export default FormFields;
