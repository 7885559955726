import { gql } from '@apollo/client';

export const GETTING_PROFESSIONALS = gql`
  subscription {
    hopy_User {
      uid
      name
      email
      address
      cpf
      contact
      active_professional
      verified_user
      account_active
      token
      cnpj
      website
      facebook
      instagram
      linkedin
      description
      sub_category_id
      city
      state
      cep
    }
  }
`;

export const GETTING_CATEGORIES = gql`
  subscription {
    hopy_Category(order_by: { name: asc }) {
      uid
      name
      description
    }
  }
`;

export const GETTING_SUB_CATEGORIES = gql`
  subscription {
    hopy_SubCategory(order_by: { name: asc }) {
      uid
      name
      category_id
    }
  }
`;

export const GETTING_ANNOUNCES = gql`
  subscription {
    hopy_Announce(order_by: { created_at: desc }) {
      checked
      contact
      created_at
      description
      id
      sub_category_id
      photo_url
      title
      uid
      updated_at
      user_id
    }
  }
`;

export const GETTING_RATINGS = gql`
  subscription {
    hopy_Ranking(order_by: { check: asc }) {
      check
      description
      rating
      uid_prof
      user_name
      user_photo_url
      id
    }
  }
`;

export const GETTING_PROFESSIONALS_NAMES = gql`
  subscription {
    hopy_User {
      uid
      name
    }
  }
`;
