import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Grid } from '@material-ui/core';

import Header from './components/Header';

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex'
  }
}));

const ProfessionalRegister = (props) => {
  const classes = useStyles();

  document.title = 'Hopy App';

  return (
    <React.Fragment>
      <div className={classes.root}>
        <Grid
          className={classes.container}
          alignItems="center"
          justify="center"
          container>
          <Header className={classes.header} />
        </Grid>
      </div>
    </React.Fragment>
  );
};

export default ProfessionalRegister;
