import React, { useCallback, useState, useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';

import clsx from 'clsx';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardHeader,
  CardContent,
  Divider,
  Grid,
  TextField,
  Typography,
  CardActions,
  Button
} from '@material-ui/core';

import {
  GETTING_CATEGORIES,
  GETTING_SUB_CATEGORIES
} from 'data/subscriptions/subscriptions';
import { UPDATE_ANNOUNCE_COMPLETE } from 'data/mutations/mutations';

import { useSubscription, useMutation } from '@apollo/client';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4)
  },
  status: {
    display: 'flex',
    alignItems: 'flex-end',
    paddingLeft: theme.spacing(2),
    marginBottom: theme.spacing(1)
  },
  label: {
    color: '#80AE38',
    fontWeight: 500
  },
  active: {
    color: '#80AE38',
    fontWeight: 500,
    paddingBottom: theme.spacing(1.2)
  },
  link: {
    color: '#fff'
  }
}));

const AnnounceUpdate = (props) => {
  const { className, ...rest } = props;
  const { announce } = props.location;

  const { data: subCategories } = useSubscription(GETTING_SUB_CATEGORIES);
  const [allSubCategories, setAllSubCategories] = useState([
    {
      name: 'Selecione uma função profissional'
    }
  ]);

  const [updateAnnounce] = useMutation(UPDATE_ANNOUNCE_COMPLETE);

  const classes = useStyles();

  let errors = [];

  console.log(announce);
  console.log(subCategories);

  useEffect(() => {
    if (subCategories) {
      setAllSubCategories((allSubCategories) => [
        ...allSubCategories,
        ...subCategories.hopy_SubCategory
      ]);
    }
  }, [subCategories]);

  const [values, setValues] = useState({
    checked: announce.checked || false,
    contact: announce.contact || '',
    created_at: announce.created_at || '',
    description: announce.description || '',
    id: announce.id || '',
    photo_url: announce.photo_url || '',
    sub_category_id: announce.sub_category_id || '',
    title: announce.title || '',
    uid: announce.uid || '',
    updated_at: announce.updated_at || '',
    user_id: announce.user_id || ''
  });

  const handleChange = useCallback(
    (event) => {
      setValues({
        ...values,
        [event.target.name]: event.target.value
      });
    },
    [values]
  );

  const restartFields = () => {
    setValues({
      category_name: '',
      checked: false,
      contact: '',
      created_at: '',
      description: '',
      id: '',
      photo_url: '',
      send_user: '',
      sub_category_id: '',
      sub_category_name: '',
      title: '',
      uid: '',
      updated_at: '',
      user_id: ''
    });
  };

  const checkUpdateForm = () => {
    if (values.title === '') errors.push('Título');
    if (values.send_user === '') errors.push('Usuário');
    if (values.description === '') errors.push('Descrição');
    if (values.contact === '') errors.push('Telefone');
    if (values.sub_category_id === '') errors.push('Função Profissional');

    if (errors.length !== 0) return false;

    return true;
  };

  const reformAnnounce = async (event) => {
    try {
      event.preventDefault();
      let ref = null;
      if (checkUpdateForm() === true) {
        ref = updateAnnounce({
          variables: {
            contact: values.contact,
            description: values.description,
            sub_category_id: values.sub_category_id,
            title: values.title,
            uid: values.uid
          }
        });
      } else {
        alert('Ops! Confira se os campos: ' + errors + ', ficaram vazios!');
        errors = [];
      }

      if (ref !== null) {
        alert('Anúncio ( ' + values.uid + ' ), foi atualizado com sucesso!');
        restartFields();
        return null;
      }
    } catch (error) {
      alert(error);
    }
  };

  return (
    <div className={clsx(classes.root, className)}>
      <Card {...rest}>
        <form onSubmit={reformAnnounce}>
          <CardHeader
            subheader="Os campos com * são obrigatórios"
            title={`Atualização do Anúncio: ${announce.uid}`}
          />
          <Divider />
          <CardContent>
            <Grid container spacing={4}>
              <Grid item md={4} xs={12}>
                <Typography className={classes.label}>Título *</Typography>
                <TextField
                  fullWidth
                  helperText="Este campo é obrigatório"
                  margin="dense"
                  name="address"
                  onChange={handleChange}
                  required
                  value={values.title}
                  variant="outlined"
                />
              </Grid>
              <Grid item md={4} xs={12}>
                <Typography className={classes.label}>Contato *</Typography>
                <TextField
                  fullWidth
                  helperText="Este campo é obrigatório"
                  margin="dense"
                  name="contact"
                  onChange={handleChange}
                  required
                  value={values.contact}
                  variant="outlined"
                />
              </Grid>
              <Grid item md={4} xs={12}>
                <Typography className={classes.label}>
                  Função Profissional *
                </Typography>
                <TextField
                  fullWidth
                  helperText="Este campo é obrigatório"
                  margin="dense"
                  required
                  name="sub_category_id"
                  onChange={handleChange}
                  select
                  SelectProps={{ native: true }}
                  value={values.sub_category_id}
                  variant="outlined">
                  {allSubCategories.map((sub) => (
                    <option key={sub.uid} value={sub.uid}>
                      {sub.name}
                    </option>
                  ))}
                </TextField>
              </Grid>
              {/* <Grid item md={4} xs={12}>
                <Typography className={classes.label}>Categoria *</Typography>
                <TextField
                  fullWidth
                  helperText="Este campo é obrigatório"
                  margin="dense"
                  required
                  name="category_id"
                  onChange={handleChange}
                  select
                  SelectProps={{ native: true }}
                  value={values.category_id}
                  variant="outlined">
                  {allCategories.map((cat) => (
                    <option key={cat.uid} value={cat.uid}>
                      {cat.name}
                    </option>
                  ))}
                </TextField>
              </Grid> */}
              <Grid item md={4} xs={12}>
                <Typography className={classes.label}>Descrição *</Typography>
                <TextField
                  fullWidth
                  helperText="Este campo é obrigatório"
                  margin="dense"
                  name="description"
                  onChange={handleChange}
                  required
                  value={values.description}
                  variant="outlined"
                />
              </Grid>
            </Grid>
          </CardContent>
          <Divider />
          <CardActions>
            <Button
              color="primary"
              onClick={reformAnnounce}
              variant="contained">
              <RouterLink className={classes.link} to="/anuncios">
                Atualizar
              </RouterLink>
            </Button>
          </CardActions>
        </form>
      </Card>
    </div>
  );
};

AnnounceUpdate.propTypes = {
  className: PropTypes.string
};

export default AnnounceUpdate;
