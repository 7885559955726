import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import Modal from 'views/Modal';

const useStyles = makeStyles((theme) => ({
  root: {},
  disapprove: {
    maxWidth: '97.93px',
    fontSize: '13px',
    background: 'red',
    '&:hover': {
      background: 'darkorange'
    }
  },
  approve: {
    maxWidth: '97.93px',
    fontSize: '13px',
    background: '#68AF27',
    '&:hover': {
      background: '#00AF00'
    }
  }
}));

const ApprovedButton = (props) => {
  const { checked, onClick, approveText, disapproveText } = props;

  const classes = useStyles();

  const [showModal, setShowModal] = useState(false);
  const toggleModal = () => setShowModal(!showModal);

  if (checked) {
    return (
      <>
        <Button
          className={classes.disapprove}
          onClick={toggleModal}
          color="secondary"
          variant="contained">
          Desaprovar
        </Button>
        {showModal ? (
          <Modal>
            <div>
              <Typography>{disapproveText}</Typography>
              <div className="buttons">
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.approve}
                  onClick={function () {
                    onClick();
                    toggleModal();
                  }}>
                  Sim
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.disapprove}
                  onClick={toggleModal}>
                  Não
                </Button>
              </div>
            </div>
          </Modal>
        ) : null}
      </>
    );
  }
  return (
    <>
      <Button
        className={classes.approve}
        onClick={toggleModal}
        color="primary"
        variant="contained">
        Aprovar
      </Button>
      {showModal ? (
        <Modal>
          <div>
            <Typography>{approveText}</Typography>
            <div className="buttons">
              <Button
                color="primary"
                variant="contained"
                className={classes.approve}
                onClick={function () {
                  onClick();
                  toggleModal();
                }}>
                Sim
              </Button>
              <Button
                color="primary"
                variant="contained"
                className={classes.disapprove}
                onClick={toggleModal}>
                Não
              </Button>
            </div>
          </div>
        </Modal>
      ) : null}
    </>
  );
};

ApprovedButton.propTypes = {
  className: PropTypes.string
};

export default ApprovedButton;
