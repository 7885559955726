import React, { useState, useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardHeader,
  CardActions,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  TablePagination
} from '@material-ui/core';

import { ApprovedButton } from '../../components';

import { useSubscription, useMutation } from '@apollo/client';
import {
  GETTING_ANNOUNCES,
  GETTING_SUB_CATEGORIES
} from 'data/subscriptions/subscriptions';
import { UPDATE_ANNOUNCE_CHECKED } from 'data/mutations/mutations';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4),
    width: '100%'
  },
  content: {
    padding: 0
  },
  inner: {
    width: '100%'
  },
  infoContainer: {
    maxHeight: '71px',
    maxWidth: '250px'
  },
  actions: {
    justifyContent: 'flex-end'
  },
  link: {
    maxWidth: '200px',
    margin: 0
  },
  title: {
    backgroundColor: '#68AF27',
    justifyContent: 'center'
  }
}));

const AnnounceList = (props) => {
  const { className, ...rest } = props;

  const classes = useStyles();

  const [updateAnnounce] = useMutation(UPDATE_ANNOUNCE_CHECKED);
  const { data: rawSubCategories } = useSubscription(GETTING_SUB_CATEGORIES);
  const { data: rawAnnounces } = useSubscription(GETTING_ANNOUNCES);

  const [announces, setAnnounces] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);

  useEffect(() => {
    if (rawAnnounces && rawSubCategories) {
      setAnnounces(rawAnnounces.hopy_Announce);
      setSubCategories(rawSubCategories.hopy_SubCategory);
    }
  }, [rawAnnounces, rawSubCategories]);

  console.log(rawSubCategories);
  console.log(rawAnnounces);

  const handlePageChange = (event, page) => {
    setPage(page);
  };

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(event.target.value);
  };

  return (
    <div className={classes.root}>
      <Card {...rest}>
        <CardHeader className={classes.title} title="Tabela de Anúncios" />
        <CardContent className={classes.content}>
          <PerfectScrollbar>
            <div className={classes.inner}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Título</TableCell>
                    <TableCell>Função</TableCell>
                    <TableCell>Descrição</TableCell>
                    <TableCell>Aprovação</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody className={classes.tableBody}>
                  {announces.slice(0, rowsPerPage).map((announce) => (
                    <TableRow
                      key={announce.id}
                      className={classes.tableRow}
                      hover>
                      <TableCell>
                        <div className={classes.infoContainer}>
                          <RouterLink
                            to={{
                              pathname: '/atualizar-anuncios',
                              announce: announce
                            }}>
                            <Typography>{announce.title}</Typography>
                          </RouterLink>
                        </div>
                      </TableCell>
                      <TableCell className={classes.infoContainer}>
                        {subCategories.map((subCategory) => {
                          if (subCategory.uid === announce.sub_category_id) {
                            return subCategory.name;
                          }
                        })}
                      </TableCell>
                      <TableCell>
                        <Typography
                          className={classes.infoContainer}
                          variant="body1">
                          {announce.description}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <ApprovedButton
                          onClick={() => {
                            updateAnnounce({
                              variables: {
                                uid: announce.uid,
                                checked: !announce.checked
                              }
                            });
                          }}
                          checked={announce.checked}
                          approveText="Aprovar esse anúncio?"
                          disapproveText="Desaprovar esse anúncio?"
                        />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </div>
          </PerfectScrollbar>
        </CardContent>
        <CardActions className={classes.actions}>
          <TablePagination
            component="div"
            count={announces.length}
            onChangePage={handlePageChange}
            onChangeRowsPerPage={handleRowsPerPageChange}
            page={page}
            rowsPerPage={rowsPerPage}
            rowsPerPageOptions={[5, 10, 20, 50, 100, 200, 500]}
          />
        </CardActions>
      </Card>
    </div>
  );
};

AnnounceList.propTypes = {
  className: PropTypes.string
};

export default AnnounceList;
