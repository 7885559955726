import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Grid } from '@material-ui/core';

import {
  TotalUsers,
  TasksProgress,
  TotalProfit,
} from './components';

import firebase from 'config/firebase';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4)
  }
}));

const Dashboard = (props) => {
  const classes = useStyles();

  const { history } = props;

  try {
    if (firebase.getCurrentFirebaseUser() === null) {
      alert('Por gentileza, faça o login primeiro!');
      history.replace('/sign-in');
      return null;
    }
    return (
      <div className={classes.root}>
        <Grid justify="center" container spacing={4}>
          <Grid item lg={3} sm={6} xl={3} xs={12}>
            <TotalUsers />
          </Grid>
          <Grid item lg={3} sm={6} xl={3} xs={12}>
            <TasksProgress />
          </Grid>
          <Grid item lg={3} sm={6} xl={3} xs={12}>
            <TotalProfit />
          </Grid>
        </Grid>
      </div>
    );
  } catch (error) {
    alert(error.message);
    history.replace('/sign-in');
  }
};

export default Dashboard;
