import app from 'firebase/app';
import 'firebase/auth';
import 'firebase/firebase-firestore';
import 'firebase/firebase-storage';

const config = {
  apiKey: 'AIzaSyC_Hfo4W200LSnWpJuDptmXbUE0WK0t6E8',
  authDomain: 'hopy-3f27d.firebaseapp.com',
  databaseURL: 'https://hopy-3f27d.firebaseio.com',
  projectId: 'hopy-3f27d',
  storageBucket: 'hopy-3f27d.appspot.com',
  messagingSenderId: '132683537985',
  appId: '1:132683537985:web:2c9027c6d612a97b38f0c0',
  measurementId: 'G-ZVM1QQYK2K'
};

class Firebase {
  constructor() {
    app.initializeApp(config);
    this.auth = app.auth();
    this.db = app.firestore();
    const storage = app.storage();
    this.usersRef = storage.ref().child('images/users');
  }

  login(email, password) {
    return this.auth.signInWithEmailAndPassword(email, password);
  }

  logout() {
    return this.auth.signOut();
  }

  async register(name, email, password, photo) {
    await this.auth.createUserWithEmailAndPassword(email, password);
    const photoRef = this.usersRef.child(
      `${this.auth.currentUser.uid}/perfilphoto/profile.png`
    );
    photoRef.put(photo);
    return this.auth.currentUser.updateProfile({
      displayName: name
    });
  }

  isInitialized() {
    return new Promise((resolve) => {
      this.auth.onAuthStateChanged(resolve);
    });
  }

  getCurrentUsername() {
    return this.auth.currentUser && this.auth.currentUser.displayName;
  }

  getCurrentFirebaseUser() {
    return this.auth.currentUser;
  }

  async getCurrentUserPhoto() {
    const userPhoto = this.usersRef.child(
      `${this.auth.currentUser.uid}/perfilphoto/profile.png`
    );
    const downloadUrl = await userPhoto.getDownloadURL();
    return downloadUrl;
  }

  async changeCurrentUserPhoto(photo) {
    const photoRef = this.usersRef.child(
      `${this.auth.currentUser.uid}/perfilphoto/profile.png`
    );
    photoRef.put(photo);
  }

  async changeUserPassword(newPassword) {
    const user = this.auth.currentUser;
    await user.updatePassword(newPassword);
    // .then(() => {
    //   console.log('Troca de senha efetuada com sucesso!');
    // })
    // .catch((error) => {
    //   console.log('Erro na troca de senha');
    // });
  }
}

export default new Firebase();
