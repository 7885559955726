import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
  Button,
  TextField
} from '@material-ui/core';
import firebase from 'config/firebase.js';

const useStyles = makeStyles(() => ({
  root: {},
  confirm: {
    marginTop: '1rem'
  }
}));

const Password = (props) => {
  const { className, ...rest } = props;

  const classes = useStyles();

  const [values, setValues] = useState({
    password: '',
    confirm: ''
  });

  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
  };

  async function changePassword() {
    try {
      if (values.password === values.confirm) {
        await firebase.changeUserPassword(values.password).then(() => {
          alert('Troca bem sucedida!');
        });
      }
    } catch (error) {
      alert(`Erro na troca de senha : ${error}`);
    }
  }

  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <form>
        <CardHeader subheader="Trocar senha" title="Senha" />
        <Divider />
        <CardContent>
          <TextField
            fullWidth
            label="Nova senha"
            name="password"
            onChange={handleChange}
            type="password"
            value={values.password}
            variant="outlined"
          />
          <TextField
            className={classes.confirm}
            fullWidth
            label="Confirmar nova senha"
            name="confirm"
            onChange={handleChange}
            type="password"
            value={values.confirm}
            variant="outlined"
          />
        </CardContent>
        <Divider />
        <CardActions>
          <Button onClick={changePassword} color="primary" variant="outlined">
            Alterar senha
          </Button>
        </CardActions>
      </form>
    </Card>
  );
};

Password.propTypes = {
  className: PropTypes.string
};

export default Password;
