import React, { useState } from 'react';
import { Link as RouterLink, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import {
  Grid,
  Button,
  TextField,
  Link,
  Checkbox,
  Typography,
  Paper,
  Input
} from '@material-ui/core';
import firebase from 'config/firebase.js';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    height: '100%',
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    [theme.breakpoints.up(600 + theme.spacing(6))]: {
      width: 600,
      marginLeft: 'auto',
      marginRight: 'auto'
    }
  },
  grid: {
    display: 'flex',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center'
  },
  paper: {
    display: 'flex',
    alignItems: 'center',
    alignSelf: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px ${theme.spacing(
      2
    )}px`,
    boxShadow: '0px 10px 30px rgba(0, 0, 0, 0.3)'
  },
  quoteContainer: {
    [theme.breakpoints.down('md')]: {
      display: 'none'
    }
  },
  quote: {
    backgroundColor: theme.palette.neutral,
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundImage: 'url(/images/auth.jpg)',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center'
  },
  quoteInner: {
    textAlign: 'center',
    flexBasis: '600px'
  },
  quoteText: {
    color: theme.palette.white,
    fontWeight: 300
  },
  name: {
    marginTop: theme.spacing(3),
    color: theme.palette.white
  },
  bio: {
    color: theme.palette.white
  },
  contentContainer: {},
  content: {
    height: '100%',
    display: 'flex'
  },
  contentHeader: {
    display: 'flex',
    alignItems: 'center',
    paddingTop: theme.spacing(5),
    paddingBototm: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  },
  logoImage: {
    marginLeft: theme.spacing(4)
  },
  contentBody: {
    flexGrow: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.down('md')]: {
      justifyContent: 'center'
    }
  },
  form: {
    flexBasis: 100,
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2)
    }
  },
  title: {
    textAlign: 'center',
    color: '#80AE38'
  },
  subtitle: {
    textAlign: 'center'
  },
  policy: {
    marginTop: theme.spacing(1),
    display: 'flex',
    alignItems: 'center'
  },
  policyCheckbox: {
    marginLeft: '-14px'
  },
  signUpButton: {
    margin: theme.spacing(2, 0),
    '&:hover': {
      backgroundColor: '#80a138'
    }
  },
  label: {
    marginTop: theme.spacing(1),
    fontWeight: 500,
    color: '#80AE38',
    fontSize: '14px'
  }
}));

const SignUp = (props) => {
  const { history } = props;

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPass, setConfirmPass] = useState('');
  const [check, setCheck] = useState(false);
  const [photo, setPhoto] = useState([]);

  const classes = useStyles();

  async function onRegister() {
    try {
      if (password === confirmPass) {
        await firebase.register(name, email, password, photo[0]);
        alert('Conta Criada com Suceso!');
        history.replace('/sign-in');
        return;
      }
      alert('As Senhas não são Iguais!');
    } catch (error) {
      alert(error.message);
    }
  }

  return (
    <div className={classes.root}>
      <Grid className={classes.grid} container>
        <Grid className={classes.content} item lg={7} xs={12}>
          <div className={classes.contentBody}>
            <Paper className={classes.paper}>
              <form
                autoComplete="off"
                className={classes.form}
                onSubmit={(event) => event.preventDefault() && false}>
                <Typography className={classes.title} variant="h2">
                  Criar Conta
                </Typography>
                <Typography
                  className={classes.subtitle}
                  color="primary"
                  gutterBottom>
                  Use seu email para criar uma conta
                </Typography>
                <Typography className={classes.label}>
                  Nome e sobrenome
                </Typography>
                <TextField
                  className={classes.textField}
                  // error={hasError('firstName')}
                  fullWidth
                  // helperText={
                  //   hasError('firstName') ? formState.errors.firstName[0] : null
                  // }
                  name="firstName"
                  onChange={(e) => setName(e.target.value)}
                  type="text"
                  value={name}
                  variant="outlined"
                />
                <Typography className={classes.label}>
                  Endereço de email
                </Typography>
                <TextField
                  className={classes.textField}
                  // error={hasError('email')}
                  fullWidth
                  // helperText={
                  //   hasError('email') ? formState.errors.email[0] : null
                  // }
                  name="email"
                  onChange={(e) => setEmail(e.target.value)}
                  type="text"
                  value={email}
                  variant="outlined"
                />
                <Typography className={classes.label}>Senha</Typography>
                <TextField
                  className={classes.textField}
                  // error={hasError('password')}
                  fullWidth
                  // helperText={
                  //   hasError('password') ? formState.errors.password[0] : null
                  // }
                  name="password"
                  onChange={(e) => setPassword(e.target.value)}
                  type="password"
                  value={password}
                  variant="outlined"
                />
                <Typography className={classes.label}>
                  Confirmar senha
                </Typography>
                <TextField
                  className={classes.textField}
                  // error={hasError('password')}
                  fullWidth
                  name="password"
                  onChange={(e) => setConfirmPass(e.target.value)}
                  type="password"
                  value={confirmPass}
                  variant="outlined"
                />
                <Typography className={classes.label}>
                  Upload de foto
                </Typography>
                <Input
                  fullWidth
                  name="photo"
                  onChange={(e) => setPhoto(e.target.files)}
                  type="file"
                  required
                />
                <div className={classes.policy}>
                  <Checkbox
                    checked={check}
                    className={classes.policyCheckbox}
                    color="primary"
                    name="policy"
                    onChange={(e) => setCheck(check ? false : true)}
                  />
                  <Typography
                    className={classes.policyText}
                    color="textSecondary"
                    variant="body1">
                    Eu li e concordo com os{' '}
                    <Link
                      color="primary"
                      component={RouterLink}
                      to="#"
                      underline="always"
                      variant="h6">
                      Termos e Condições
                    </Link>
                  </Typography>
                </div>
                {/* {hasError('policy') && (
                    <FormHelperText error>
                      {formState.errors.policy[0]}
                    </FormHelperText>
                  )} */}
                <Button
                  className={classes.signUpButton}
                  color="primary"
                  fullWidth
                  size="large"
                  type="submit"
                  variant="contained"
                  onClick={onRegister}>
                  Criar uma conta agora
                </Button>
                <Typography color="textSecondary" variant="body1">
                  Já possui uma conta?{' '}
                  <Link component={RouterLink} to="/sign-in" variant="h6">
                    Realizar login
                  </Link>
                </Typography>
              </form>
            </Paper>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

SignUp.propTypes = {
  history: PropTypes.object
};

export default withRouter(SignUp);
