import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { Button } from '@material-ui/core';

import { SearchInput } from 'components';

const useStyles = makeStyles((theme) => ({
  root: {},
  row: {
    height: '42px',
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(1)
  },
  spacer: {
    flexGrow: 1
  },
  importButton: {
    marginRight: theme.spacing(1)
  },
  exportButton: {
    marginRight: theme.spacing(1)
  },
  searchInput: {
    marginRight: theme.spacing(1),
    '&:hover': {
      boxShadow: '3px 3px 3px rgba(0, 0, 0, 0.5)'
    }
  }
}));

const UsersToolbar = (props) => {
  const { className, callbackParent,...rest } = props;

  const classes = useStyles();

  const [searchName, setSearchName] = useState('');

  const handleSearchName = () => {
    callbackParent(searchName);
  }

  return (
    <div {...rest} className={clsx(classes.root, className)}>
      <div className={classes.row}>
        <SearchInput
          className={classes.searchInput}
          placeholder="Pesquisar usuário"
          value={searchName}
          onChange={(e) => setSearchName(e.target.value)}
        />
        <Button color="primary" variant="contained" onClick={handleSearchName}>
          Pesquisar
        </Button>
      </div>
    </div>
  );
};

UsersToolbar.propTypes = {
  className: PropTypes.string
};

export default UsersToolbar;
